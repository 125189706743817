<script>
import { mapState } from 'vuex'
import { toDinheiro } from '@/util/Formater.js'
import { vMaska } from 'maska'
import { partnerConfigurationModelType } from '@/util/constants'
import BarCodeScannerDialog from '@/components/bag/BarCodeScannerDialog.vue'
import roundHalfEven from 'round-half-even'

export default {
  name: 'BagWithContents',
  components: {
    BarCodeScannerDialog: BarCodeScannerDialog
  },
  props: {
    bagType: {
      require: true
    },
    bagCategory: {
      require: true
    },
    bagContent: {
      require: true
    },
    bagDescription: {
      require: true
    }
  },
  data() {
    return {
      bagSelectedOption: null,
      dialogHeight: 900,
      search: null,
      bagId: null,
      bagPrice: 0,
      bagCommercialPrice: 0,
      totalPriceOfProducts: 0,
      bagTypes: [],
      products: [],
      filteredProducts: [],
      createdBag: [],
      pagination: {
        total: 0,
        limit: 10,
        first: 0
      },
      loadingCreateBag: false,
      itensPerPage: 0,
      bagCount: 0,
      showErrorAlert: false,
      showDialogContentCreatedBag: false,
      showDialogBarCodeScan: false,
      contentCustomPrice: null,
      loadingMoreProducts: false,
      customPriceGondolaIsEnabled: false,
      contentGondolaBagEnabled: false,
      customProductWeightSearchConfiguration: null,
      customProductUnitSearchConfiguration: null,
      isMounted: false,
      productTableFields: [
        //TODO: add coluna imagem quando integração retornar imagens.
        // {
        //   value: 'image',
        //   align: 'center',
        //   sortable: false,
        //   width: 30
        // },
        {
          value: 'name',
          align: 'center',
          sortable: false,
          width: '500px'
        },
        {
          value: 'price',
          align: 'center',
          sortable: false,
          width: '80px'
        },
        {
          value: 'id',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  directives: { maska: vMaska },
  methods: {
    toDinheiro,
    roundHalfEven,

    getPartner() {
      return this.$store.getters.getEstabelecimento
    },

    getPartnerId() {
      return this.$store.getters.getEstabelecimento.id
    },

    getTotalPriceOfProducts() {
      const totalPrice = this.products
        ? this.products.reduce((total, item) => {
            return (
              total + roundHalfEven(Number(item.product_count) * item.price, 2)
            )
          }, 0)
        : 0.0

      if (this.customPriceGondolaIsEnabled) {
        this.bagPrice = totalPrice
        this.bagCommercialPrice = null
      }

      return totalPrice
    },

    listMoreProducts() {
      this.loadingMoreProducts = true
      this.pagination.first = this.pagination.first + 10
      this.pagination.total = this.pagination.total + 10

      this.filterProducts()
    },

    selectedBagType(bagTypeCategory) {
      this.bagId = bagTypeCategory.id
      this.bagPrice = bagTypeCategory.valor_referencia
      this.bagCommercialPrice = bagTypeCategory.valor
    },

    changeQuantityProduct(item) {
      if (item.product_count < 1 || item.price === 0) return
      const existingItem = this.products.find(
        (product) => item.id === product.id
      )
      if (existingItem) {
        existingItem.product_count = Number(item.product_count)
      } else {
        this.products.push(item)
      }
    },

    isDisabledInputQuantity(item) {
      return item.price === 0
    },

    isDisabledAddButton(item) {
      return item.product_count >= 99 || item.price === 0
    },

    isDisabledRemoveButton(item) {
      return (
        item.product_count == 0 ||
        item.price === 0 ||
        item.product_count == null
      )
    },

    incrementQuantityProduct(index) {
      if (!this.filteredProducts[index].product_count) {
        this.$set(this.filteredProducts, index, {
          ...this.filteredProducts[index],
          product_count: 0
        })
      }

      this.filteredProducts[index].product_count++

      this.addProductInBag(index)
    },

    decrementQuantityProduct(index) {
      if (!this.filteredProducts[index].product_count) {
        this.$set(this.filteredProducts, index, {
          ...this.filteredProducts[index],
          product_count: 0
        })
        return
      }
      this.filteredProducts[index].product_count--

      this.removeProductInBag(index)
    },

    incrementQuantityBag() {
      this.bagCount++
    },

    decrementQuantityBag() {
      this.bagCount--
    },

    addProductInBag(index) {
      const existingProduct = this.products.find(
        (product) => this.filteredProducts[index].id === product.id
      )
      if (existingProduct && this.itemUnitIsUnitary(existingProduct)) {
        existingProduct.product_count = Number(
          this.filteredProducts[index].product_count
        )

        const productIndex = this.products.findIndex(
          (product) => product.id == this.filteredProducts[index].id
        )

        this.$set(this.products, productIndex, {
          ...existingProduct
        })
      } else {
        this.products.push(this.filteredProducts[index])
      }
    },

    removeProductInBag(index) {
      const existingProduct = this.products.find(
        (product) => this.filteredProducts[index].id === product.id
      )

      if (existingProduct) {
        existingProduct.product_count = Number(
          this.filteredProducts[index].product_count
        )

        if (existingProduct.product_count == 0) {
          const index = this.products.indexOf(existingProduct)
          this.products.splice(index, 1)
        }
      }
    },

    btnRemoveProductInBag(item) {
      if (this.itemUnitIsUnitary(item)) {
        const existingProductFiltered = this.filteredProducts.find(
          (product) => item.id === product.id
        )
        if (existingProductFiltered) {
          existingProductFiltered.product_count = 0
        }
      }
      const existingProduct = this.products.find(
        (product) => item.id === product.id
      )
      const index = this.products.indexOf(existingProduct)
      this.products.splice(index, 1)
    },

    readWeightProductBarCode() {
      if (this.search && this.customProductWeightSearchConfiguration) {
        const regexWeightSearch =
          this.customProductWeightSearchConfiguration.partner_configuration_params.find(
            (param) => param.key === 'SEARCH_FIELD_REGEX'
          )?.value
        const weightIndex =
          this.customProductWeightSearchConfiguration.partner_configuration_params.find(
            (param) => param.key === 'SEARCH_FIELD_REGEX_WEIGHT_INDEX'
          )?.value
        if (regexWeightSearch) {
          const regexp = new RegExp(regexWeightSearch, 'g')
          const matches = Array.from(this.search.matchAll(regexp))
          if (
            matches &&
            matches[0] &&
            weightIndex !== undefined &&
            matches[0][Number(weightIndex)]
          ) {
            return matches[0][Number(weightIndex)]
          }
        }
      }
    },
    readUnitProductBarCode() {
      if (this.search && this.customProductUnitSearchConfiguration) {
        const regexWeightSearch =
          this.customProductUnitSearchConfiguration.partner_configuration_params.find(
            (param) => param.key === 'SEARCH_FIELD_REGEX'
          )?.value
        const unitIndex =
          this.customProductUnitSearchConfiguration.partner_configuration_params.find(
            (param) => param.key === 'SEARCH_FIELD_REGEX_UNIT_INDEX'
          )?.value
        if (regexWeightSearch) {
          const regexp = new RegExp(regexWeightSearch, 'g')
          const matches = Array.from(this.search.matchAll(regexp))
          if (
            matches &&
            matches[0] &&
            unitIndex !== undefined &&
            matches[0][Number(unitIndex)]
          ) {
            return matches[0][Number(unitIndex)]
          }
        }
      }
    },

    filterProducts(searchByScanner = false) {
      const quantityFromWeightBarCode = this.readWeightProductBarCode()
      const quantityFromUnitBarCode = this.readUnitProductBarCode()

      const exactSearch =
        quantityFromWeightBarCode !== undefined ||
        quantityFromUnitBarCode !== undefined

      const result = this.getProductsSearch(
        this.search,
        this.pagination.first,
        this.pagination.limit,
        exactSearch
      )
      result
        .then((response) => {
          if (response) {
            if (this.filteredProducts) {
              this.filteredProducts.push(...response.data)
            } else {
              this.filteredProducts = response.data
            }

            if (!this.pagination.total) {
              this.pagination.total = 10
            } else {
              this.pagination.total = this.filteredProducts.length
            }

            if (this.filterProducts) {
              this.filteredProducts.forEach((responseProduct) => {
                const existingItem = this.products.find(
                  (existingProduct) => responseProduct.id === existingProduct.id
                )

                if (
                  existingItem &&
                  this.itemUnitIsUnitary(existingItem) &&
                  existingItem.product_count > 0
                ) {
                  responseProduct.product_count = existingItem.product_count
                }
              })
            }

            if (
              (searchByScanner || exactSearch) &&
              this.filteredProducts &&
              this.filteredProducts.length
            ) {
              const product = this.filteredProducts[0]
              let productQuantity = null

              if (quantityFromWeightBarCode !== undefined) {
                let quantityMultiplicator = null

                switch (product.unit.toUpperCase()) {
                  case 'KG':
                    quantityMultiplicator = 0.001
                    break
                  case 'G':
                    quantityMultiplicator = 0.001
                    break
                  default:
                    quantityMultiplicator = 1
                    break
                }

                productQuantity =
                  Number(quantityFromWeightBarCode) * quantityMultiplicator
              } else if (quantityFromUnitBarCode !== undefined) {
                productQuantity = Number(quantityFromUnitBarCode)
              } else {
                productQuantity = 1
              }

              if (this.filteredProducts[0].product_count) {
                this.filteredProducts[0].product_count =
                  Number(this.filteredProducts[0].product_count) +
                  Number(productQuantity)
              } else {
                this.filteredProducts[0].product_count = Number(productQuantity)
              }
              this.addProductInBag(0)
            }
          }
        })
        .finally((_) => {
          this.loadingMoreProducts = false
        })

      return this.filteredProducts
    },

    cleanPage() {
      this.erros = []
      this.bagSelectedOption = null
      this.bagId = null
      this.search = null
      this.bagPrice = null
      this.products = []
      this.createdBag = []
      this.bagCount = 0
      this.showErrorAlert = false
      this.errorMessage = null
      this.changeSearch()
    },

    showCreatedBag(bagResponse) {
      this.$gtag.event('addbag_to_sell')
      this.createdBag = bagResponse.bag
      this.showDialogContentCreatedBag = true
      this.$emit('updateGondola', bagResponse)
    },

    closeDialogContentCreatedBag() {
      this.cleanPage()
      this.showDialogContentCreatedBag = false
    },

    validateTotalPriceOfProducts() {
      this.totalPriceOfProducts = this.getTotalPriceOfProducts()
      return this.totalPriceOfProducts >= this.bagCommercialPrice
    },

    getProductsSearch(
      search,
      first = 0,
      limit = this.pagination.limit,
      exactSearch = false
    ) {
      this.erros = []
      this.loading = true

      return this.$http
        .get(
          `/v1/partners/${this.getPartnerId()}/products-model?search=${
            search ?? ''
          }&first=${first}&limit=${limit}&exact_search=${exactSearch}`
        )
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getBagTypes() {
      this.erros = []
      this.bagWithContentsLoading = true
      this.$http
        .get(
          `/v1/partners/${this.getPartnerId()}/bags?type=${
            this.bagType
          }&category=${this.bagCategory}`
        )
        .then((response) => {
          this.bagTypes = response.data
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.bagWithContentsLoading = false
        })
    },

    changeSearch(event = null, searchByScanner = false) {
      this.pagination.first = 0
      this.filteredProducts = []
      this.filterProducts(searchByScanner)
    },

    createBag() {
      this.loadingCreateBag = true
      if (this.validateTotalPriceOfProducts()) {
        const bagRequest = {
          id: this.bagId,
          partner_id: this.getPartnerId(),
          category: this.bagCategory,
          type: this.bagType,
          reference_price: this.bagPrice,
          price: this.bagCommercialPrice,
          content: this.bagContent,
          description: this.bagDescription
        }

        const bagItems = this.products.map((bagItem) => ({
          external_id: bagItem.external_id,
          external_code: bagItem.external_code,
          quantity: bagItem.product_count
        }))

        this.$http
          .post(`/v2/partners/${this.getPartnerId()}/gondolas`, {
            quantity: Number(this.bagCount),
            bag: { ...bagRequest, products: bagItems },
            gondola_bag_id: null
          })
          .then((bagResponse) => this.showCreatedBag(bagResponse.data))
          .catch((error) => {
            this.erros.push(error.message)
            this.errorMessage = error.response.data
            this.showErrorAlert = true
          })
          .finally(() => {
            this.loadingCreateBag = false
          })
      } else {
        this.errorMessage =
          'O valor total dos produtos deve ser igual ou maior que o valor da sacola!'
        this.showErrorAlert = true
        this.loadingCreateBag = false
      }
    },
    openDialogBarCodeScan() {
      this.showDialogBarCodeScan = true
      this.$refs.refBarCodeScanner?.enableCamera()
    },
    closeDialogBarCodeScan() {
      this.showDialogBarCodeScan = false
    },
    searchProuctFromScanner(productCode) {
      if (productCode) {
        this.search = productCode
        this.changeSearch(null, true)
      }
    },
    calculateProductPrice(product) {
      if (!this.itemUnitIsUnitary(product)) {
        return roundHalfEven(product.price * product.product_count, 2)
      }
      return product.price
    },
    itemUnitIsUnitary(item) {
      if (!item?.unit) return false
      return ['UN', 'KIT'].includes(item.unit.toUpperCase())
    },
    closeBagWithContentDialog() {
      this.$emit('closeBagWithContentDialog')
    }
  },
  computed: {
    ...mapState(['estabelecimento']),
    items() {
      return this.filteredProducts
    },
    isMobile() {
      return this.isMounted && ['xs'].includes(this.$vuetify.breakpoint.name)
    },
    tableHeight() {
      return this.dialogHeight * (this.isMobile ? 0.2 : 0.6)
    },
    enableBtnBagCreate() {
      return (
        this.products.length > 0 && this.bagPrice && Number(this.bagCount) > 0
      )
    }
  },
  async mounted() {
    this.customPriceGondolaIsEnabled =
      this.$store.getters.getEstabelecimento.enabled_configurations?.find(
        (feature) =>
          feature === partnerConfigurationModelType.BAG_DYNAMIC_PRICE_MODEL
      ) !== undefined
    this.contentGondolaBagEnabled =
      this.$store.getters.getEstabelecimento.enabled_configurations?.find(
        (feature) =>
          feature === partnerConfigurationModelType.BAG_WITH_CONTENT_MODEL
      ) !== undefined

    this.customProductWeightSearchConfiguration =
      this.$store.getters.getEstabelecimento.configurations?.find(
        (config) =>
          config.name ===
          partnerConfigurationModelType.CUSTOM_PRODUCT_WEIGHT_SEARCH
      )

    this.customProductUnitSearchConfiguration =
      this.$store.getters.getEstabelecimento.configurations?.find(
        (config) =>
          config.name ===
          partnerConfigurationModelType.CUSTOM_PRODUCT_UNIT_SEARCH
      )

    this.isMounted = true
  },
  created() {
    if (!this.customPriceGondolaIsEnabled) {
      this.getBagTypes()
    }
    this.filterProducts()
  }
}
</script>

<template>
  <v-container class="white products-container-dialog" p-8>
    <v-alert
      v-if="showErrorAlert"
      class="alert error-text"
      border="left"
      color="#F2A1A1"
    >
      {{ this.errorMessage }}
    </v-alert>
    <v-icon
      @click="closeBagWithContentDialog"
      style="position: absolute; top: 0; right: 0"
    >
      mdi-close
    </v-icon>
    <v-row class="mb-0">
      <v-col cols="12" sm="12" md="6">
        <p class="section-title">
          <strong class="section-products-list">
            Adicione os produtos na sacola
          </strong>
        </p>
        <v-row class="pb-0 pt-0 mb-5">
          <v-col cols="9" sm="8" md="8" lg="9" xl="10" class="pb-0 pt-0">
            <v-text-field
              v-model="search"
              label="Busque pelo nome do produto"
              class="input-search pt-0"
              append-icon="mdi-barcode-scan"
              @click:append="openDialogBarCodeScan"
              clearable
              v-on:keyup.enter="changeSearch"
              hide-details
            />
          </v-col>
          <v-col cols="3" sm="4" md="4" lg="3" xl="2" align="right">
            <v-btn color="primary" style="width: 100%" @click="changeSearch">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          id="product-model-table"
          ref="product-model-table"
          primary-key="id"
          stacked="md"
          class="products-model-table"
          hover
          show-empty
          responsive
          hide-default-footer
          :loading="loading"
          loading-text="Carregando dados..."
          no-data-text="Não há produtos cadastrados"
          :items="items"
          :items-per-page="this.pagination.total"
          :headers="productTableFields"
          hide-default-header
          :height="tableHeight"
          border-bottom="none"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-row align="start" no-gutters>
              <p class="product-text-name" :title="item.name">
                {{ item.name }}
              </p>
            </v-row>
            <v-row align="start" no-gutters>
              <p class="product-text-code">
                <Strong>Cód.:</Strong> {{ item.external_code }}
              </p>
            </v-row>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <p class="product-text-price">
              {{ toDinheiro(item.price ? item.price : 0.0) }}
            </p>
          </template>
          <template v-slot:[`item.id`]="{ item, index }">
            <div class="d-flex justify-start mt-1">
              <v-btn
                v-if="itemUnitIsUnitary(item)"
                fab
                text
                :key="item.id + '-' + 'btn-decrement'"
                width="28"
                height="28"
                @click="decrementQuantityProduct(index)"
                :disabled="isDisabledRemoveButton(item)"
              >
                <img
                  width="14"
                  height="14"
                  src="@/assets/images/akar-icons_circle-minus-fill.svg"
                  :class="{
                    'button-icon-disabled': isDisabledRemoveButton(item)
                  }"
                />
              </v-btn>
              <input
                v-if="itemUnitIsUnitary(item)"
                type="text"
                class="ml-1 mr-1"
                style="width: 20px; text-align: center; font-size: 13px"
                v-model="item.product_count"
                :key="item.id + 'input'"
                maxlength="2"
                @change="changeQuantityProduct(item)"
                :disabled="isDisabledInputQuantity(item)"
                v-maska
                data-maska="0"
                data-maska-tokens="0:\d:multiple"
              />
              <div v-else>
                <input
                  type="text"
                  style="width: 40px; text-align: center; font-size: 13px"
                  v-model="item.product_count"
                  :key="item.id + 'input-decimal'"
                  maxlength="6"
                  @change="changeQuantityProduct(item)"
                  disabled
                  v-maska
                  placeholder="0.000"
                  data-maska="0.999"
                  data-maska-tokens="0:\d:multiple|9:\d:optional"
                />
                <span> / {{ item.unit }}</span>
              </div>
              <v-btn
                v-if="itemUnitIsUnitary(item)"
                fab
                text
                width="28"
                height="28"
                :key="item.id + '-' + 'btn-increment'"
                @click="incrementQuantityProduct(index)"
                :disabled="isDisabledAddButton(item)"
              >
                <img
                  width="14"
                  height="14"
                  src="@/assets/images/akar-icons_circle-plus-fill.svg"
                  :class="{
                    'button-icon-disabled': isDisabledAddButton(item)
                  }"
                />
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <v-row align="center" justify="center" class="mt-3">
          <v-btn
            color="primary"
            @click="listMoreProducts"
            :loading="this.loadingMoreProducts"
            >CARREGAR MAIS</v-btn
          >
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="section-title section-products-added">
          <strong class="">Produtos adicionados</strong>
        </p>
        <v-card
          outlined
          color="transparent"
          class="mx-auto selected-products-container"
        >
          <v-list class="products-list-added">
            <v-list-item
              v-for="(product, index) in this.products"
              :key="product.id + '-' + index"
            >
              <v-list-item-content width="200">
                <v-list-item-title class="list-item-title">
                  <span :title="product.name"> {{ product.name }} </span>
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap" style="font-size: 0.7em"
                  >x {{ product.product_count }}
                  {{ !itemUnitIsUnitary(product) ? ` / ${product.unit}` : '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content style="align-items: end">
                <v-list-item-title class="text-wrap list-item-title-value">
                  {{ toDinheiro(calculateProductPrice(product) ?? 0.0) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  fab
                  text
                  width="28"
                  height="28"
                  @click="btnRemoveProductInBag(product)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
        <div>
          <v-row class="row-price">
            <v-col>
              <p v-if="!customPriceGondolaIsEnabled" class="p-price">
                Valor original
              </p>
              <p class="p-total-price">Valor sacola</p>
            </v-col>
            <v-col style="text-align: right">
              <p
                :class="
                  !this.customPriceGondolaIsEnabled
                    ? 'p-price'
                    : 'p-total-price'
                "
              >
                {{ toDinheiro(this.getTotalPriceOfProducts()) }}
              </p>
              <p v-if="!customPriceGondolaIsEnabled" class="p-total-price">
                {{ toDinheiro(this.bagPrice ?? 0.0) }}
              </p>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="!customPriceGondolaIsEnabled">
          <v-col>
            <v-radio-group v-model="bagSelectedOption">
              <p class="section-title ma-2 pa-0">
                <strong>Escolha o valor da sacola</strong>
              </p>
              <v-row>
                <v-col v-for="(bag, index) in bagTypes" :key="index">
                  <v-card class="pa-2 card-option">
                    <v-radio
                      class="ma-0 pa-0 text-no-wrap"
                      :label="toDinheiro(bag.valor_referencia)"
                      :value="bag.id"
                      @click="selectedBagType(bag)"
                    ></v-radio>
                    <v-card-text class="ma-0 pa-0 card-option-text">
                      <p
                        class="ma-0 pa-0 mt-0 text-no-wrap card-option-text-type"
                      >
                        {{ bag.tipo }}
                      </p>
                      <p
                        class="ma-0 pa-0 mt-0 text-no-wrap card-option-text-category"
                      >
                        {{ bag.category }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" cols="5">
            <p class="text-lg-body-1 text-body-2 text-sm-caption ma-0 pa-0">
              <strong>Qtde. sacolas</strong>
            </p>

            <div class="d-flex justify-start mt-1">
              <v-btn
                fab
                text
                width="28"
                height="28"
                @click="decrementQuantityBag"
                :disabled="Number(bagCount) === 0"
              >
                <img
                  width="14"
                  height="14"
                  src="@/assets/images/akar-icons_circle-minus-fill.svg"
                />
              </v-btn>
              <input
                type="text"
                class="ml-1 mr-1"
                style="width: 20px; text-align: center; font-size: 13px"
                v-model="bagCount"
                maxlength="2"
                v-maska
                data-maska="0"
                data-maska-tokens="0:\d:multiple"
              />
              <v-btn
                fab
                text
                width="28"
                height="28"
                @click="incrementQuantityBag"
                :disabled="Number(bagCount) >= 99"
              >
                <img
                  width="14"
                  height="14"
                  src="@/assets/images/akar-icons_circle-plus-fill.svg"
                />
              </v-btn>
            </div>
          </v-col>
          <v-col lg="6" cols="7" align="right">
            <v-btn
              color="primary"
              class="mt-8 mb-0"
              :class="{
                'mr-2 ': isMobile
              }"
              :disabled="!enableBtnBagCreate"
              @click="createBag"
              :loading="loadingCreateBag"
            >
              CRIAR SACOLA
              <v-progress-circular
                v-if="loadingCreateBag"
                indeterminate
                color="white"
                :size="25"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogContentCreatedBag" max-width="35rem">
      <div class="dialog-container">
        <span class="dialog-title mb-4 d-flex justify-center">
          Sacola criada com sucesso 🎉
        </span>
        <div class="w-100 pl-4">
          <p class="ma-0">
            <span class="dialog-info-description main"> Código: </span>
            <span class="dialog-info-value main">
              {{ this.createdBag.tag }}</span
            >
          </p>
          <p class="ma-0">
            <span class="dialog-info-description"> Tipo: </span>
            <span class="dialog-info-value"> {{ this.createdBag.type }} </span>
          </p>
          <p class="ma-0">
            <span class="dialog-info-description"> Categoria: </span>
            <span class="dialog-info-value">
              {{ this.createdBag.category }}
            </span>
          </p>
          <p class="ma-0">
            <span class="dialog-info-description"> Quantidade: </span>
            <span class="dialog-info-value">
              {{ this.createdBag.quantity }}
            </span>
          </p>
          <p class="ma-0">
            <span class="dialog-info-description"> Valor: </span>
            <span class="dialog-info-value">
              {{ toDinheiro(this.createdBag.reference_price) }}</span
            >
          </p>
        </div>
        <v-btn
          color="primary"
          class="mt-8 mb-0"
          @click="closeDialogContentCreatedBag"
        >
          Fechar
        </v-btn>
      </div>
    </v-dialog>
    <v-dialog
      v-model="showDialogBarCodeScan"
      width="70%"
      height="50%"
      persistent
    >
      <BarCodeScannerDialog
        @closeDialogBarCodeScan="closeDialogBarCodeScan"
        @searchProuctFromScanner="searchProuctFromScanner"
        ref="refBarCodeScanner"
      />
    </v-dialog>
  </v-container>
</template>

<style scoped>
@import 'BagWithContents.scss';
</style>
